import { nanoid } from 'nanoid';
import cv from '../pdf/cv.pdf';
import reactIcon from '../images/reactIcon.png';
import formikIcon from '../images/formikIcon.png';
import styledIcon from '../images/styledIcon.jpeg';
import reactBootstrapIcon from '../images/reactBootstrap.jpeg';
import bootstrapIcon from '../images/bootstrap.png';
import sassIcon from '../images/sassIcon.png';
import rwdIcon from '../images/rwd.jpg';
import lodashIcon from '../images/lodash.png';
import firebaseIcon from '../images/firebase.png';

// HEAD DATA
export const headData = {
  title: 'Paweł Bożejewski', // e.g: 'Name | Developer'
  lang: 'pl', // e.g: en, es, fr, jp
  description: 'Welcome to my website', // e.g: Welcome to my website
};

// HERO DATA
export const heroData = {
  title: 'Hello, my name is',
  name: ' Paweł Bożejewski',
  subtitle: 'I am a front-end web developer',
  cta: 'About me',
};

// ABOUT DATA
export const aboutData = {
  img: 'profile.jpg',
  paragraphOne:
    "Junior web developer with one year  experience in coding.  I successfully completed CodersLab's Front-End Developer React Bootcamp. Curently  I improve my knowledge in Web technology's (React, JavaScript, HTML and CSS). I am focused on expanding my web developer skills. I am looking for new challenges.",
  paragraphTwo:
    'I am self-motivated and hard-working person, on a daily bases I enjoy working closely with other people and solving problems. ',
  resume: cv, // if no resume, the button will not show up
};

// PROJECTS DATA
export const projectsData = [
  {
    id: nanoid(),
    img: 'firstProjct.jpg',
    title: 'MPR GROUP',
    info:
      'Web application which represents business image of company and also the scope of activities on the market.',
    icon1: { src: reactIcon, text: 'React' },
    icon2: { src: formikIcon, text: 'Formik' },
    icon3: { src: rwdIcon, text: 'RWD' },
    icon4: { src: styledIcon, text: 'Styled-components' },
    icon5: { src: reactBootstrapIcon, text: 'React Bootstrap' },
    icon6: { src: sassIcon, text: 'Sass' },

    url: 'https://mojzesz13.github.io/Mpr/#/',
    repo: 'https://github.com/Mojzesz13/Mpr', // if no repo, the button will not show up
  },
  {
    id: nanoid(),
    img: 'Ola.jpg',
    title: 'Pracownia mody Ola',
    info:
      'Website which helps ladies find the perfect wedding dresses and answer the most common questions about projecting and sewing dresses.',
    icon1: { src: reactIcon, text: 'React' },
    icon2: { src: rwdIcon, text: 'RWD' },
    icon3: { src: lodashIcon, text: 'Lodash' },
    icon4: { src: bootstrapIcon, text: 'Bootstrap' },
    icon5: { src: sassIcon, text: 'Sass' },
    icon6: { src: '', text: '' },
    url: 'https://mojzesz13.github.io/PracowniaOla/#/',
    repo: 'https://github.com/Mojzesz13/PracowniaOla', // if no repo, the button will not show up
  },
  {
    id: nanoid(),
    img: 'owdr.jpg',
    title: 'Oddam w dobre ręce',
    info:
      '"Oddam w dobre ręce" is create with a thought about all who want help other people and dispose of unnecessary items.',
    icon1: { src: reactIcon, text: 'React' },
    icon2: { src: rwdIcon, text: 'RWD' },
    icon3: { src: lodashIcon, text: 'Lodash' },
    icon4: { src: firebaseIcon, text: 'Firebase' },
    icon5: { src: sassIcon, text: 'Sass' },
    icon6: { src: '', text: '' },
    url: 'https://mojzesz13.github.io/Oddam_w_dobre_rece/#/',
    repo: 'https://github.com/Mojzesz13/Oddam_w_dobre_rece', // if no repo, the button will not show up
  },
];

// CONTACT DATA
export const contactData = {
  cta: '',
  btn: '',
  email: 'bozejewski@gmail.com',
};

// FOOTER DATA
export const footerData = {
  networks: [
    {
      id: nanoid(),
      name: 'linkedin',
      url: 'https://www.linkedin.com/in/pawel-bozejewski/',
    },
    {
      id: nanoid(),
      name: 'github',
      url: 'https://github.com/Mojzesz13',
    },
  ],
};

// Github start/fork buttons
export const githubButtons = {
  isEnabled: true, // set to false to disable the GitHub stars/fork buttons
};
